import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect, useCallback } from 'react';

function App() {
  const [apiUrl, setApiUrl] = useState('');
  // State to store the current time
  const [currentTime, setCurrentTime] = useState('');

  const fetchApiUrl = async () => {
    try {
      const response = await fetch('https://path-to-server.master-mk-lee.workers.dev');
      if (!response.ok) {
        throw new Error('Failed to fetch API URL');
      }
      const data = await response.json();
      setApiUrl(data.api_url);
    } catch (error) {
      console.error('Error fetching the API URL:', error);
    }
  };

  // Function to fetch the current time from the server
  const fetchTime = useCallback(async () => {
    if (!apiUrl) return;
    try {
      const response = await fetch(`${apiUrl}/api/time`);
      if (!response.ok) {
        throw new Error('Failed to fetch time');
      }
      const data = await response.json();
      setCurrentTime(data.current_time);
    } catch (error) {
      console.error('Error fetching the time:', error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchApiUrl();
  }, []);

  // useEffect to fetch the time every second
  useEffect(() => {
    if (apiUrl) {
      // Fetch the time immediately when the component mounts
      fetchTime();

      // Set an interval to fetch the time every second
      const intervalId = setInterval(fetchTime, 500);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [apiUrl, fetchTime]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Current Time</h1>
        <p style={{ fontSize: '2em' }}>{currentTime || 'Loading...'}</p> {/* 상태가 없을 때 로딩 메시지 표시 */}
      </div>
    </div>

  );
}

export default App;
